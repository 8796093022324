import React, {Fragment, useEffect, useState} from 'react'
import {ReactSVG} from 'react-svg'
import PropTypes from 'prop-types'
import DynamicNumber from 'react-dynamic-number'
import {getTotalFormattedNumeric} from '../../Utils'
import DPModalPreview from './DPModalPreview'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import {colors, EXT_IMG, EXT_OTHER_IMG, EXT_PNG, pathServer, WIDTH_VIEW_MOBILE} from '../../../common/Constants'


const StyledRow = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    .icon-dropzone{
      background-image: url(${pathServer.PATH_IMG + "icon/ic_receipt_2px.svg"});
      width: 16px;
      height: 16px;
      background-size: contain;
      border: none;
      &:hover{
        cursor:pointer;
      }
    }
`;

const getStyle = (error, key) => error[key] ? {borderColor: colors.errorLabel} : {}

const RowCost = ({index,key, cost, onChange, openTaskRepair, removeTaskRepair, onDropPhoto, showActionRow,receipts,deleteReceipt,
                   openRecipes, updateShowRecipe, showRecipe,onClosePreview, isCarOrTask, showAlert,rowActive, updateRowActive,
                   costOnBlur, error, className='body-cost-fields'}) => {
  const [receiptsImages, setReceipts] = useState([]);
  const [costState, setCostState] = useState(cost);
  const onDrop = (file) => {
    const imageUrl = URL.createObjectURL(file[0])
    setReceipts([...receiptsImages, {path: file[0].path, url: imageUrl ,name: file[0].name, file: file[0]}])
    onDropPhoto(file[0],imageUrl);
    updateShowRecipe(true);
  };

  useEffect(() => {
    setReceipts(receipts.filter((r) => !r.id))
  }, [receipts])

  const handleDeleteReceipt = (id,countReceipts) =>{
    deleteReceipt(id, index, costState?.id, countReceipts);
    if(countReceipts === 0){
      updateShowRecipe(false);
    }
  }
  const handleOpenRecipe = (repairId, file) => {
    if(!repairId) {
      openRecipes(index, repairId)
      onDrop(file)
    }else if(file){
      onDrop(file)
    } else{
      updateShowRecipe(true)
    }
    updateRowActive(index);
  };

  const onChangeInput = (name, e) => {
    costState[name] = e.target.value
    setCostState({...costState})
    onChange(costState, index)
  }

  return(
    <StyledRow className={className} key={`cost-${key}`}>
        <div className="info-title">
          <span className="title-input">Expense description</span>
          <input id="description" type="text" className="cost-input" name="description" value={costState.description}
                 style={getStyle(error,"description")}
                 onChange={(e) => onChangeInput("description", e)}/>
        </div>
          <div className="info-price">
            <span className="title-input">Labor</span>
            <span className="currencyinput"><i className="fa fa-dollar"/></span>
            <DynamicNumber key={'labor'-key} maxLength="10" name="labor" className="cost-input"  value={cost.labor} placeholder="200.00" positive={true} negative={true} thousand={true}
                           style={getStyle(error,"labor")}  onChange={(e) => onChangeInput("labor", e)} separator={'.'} integer={10} fraction={2} onBlur={(e)=>costOnBlur(index, e)}/>
          </div>
          <div className="info-price">
            <span className="title-input">Part</span>
            <span className="currencyinput"><i className="fa fa-dollar"/> </span>
            <DynamicNumber key={'part'-key} maxLength="10" name="part" className="cost-input" value={cost.part} placeholder="0.00" positive={true} negative={true} thousand={true}
                           style={getStyle(error,"part")} onChange={(e) => onChangeInput("part", e)} separator={'.'} integer={10} fraction={2} onBlur={(e)=>costOnBlur(index, e)}/>
          </div>
          <div className="info-price">
            <span className="title-input">Total</span>
            <span className="currencyinput"><i className="fa fa-dollar"/> </span>
            <DynamicNumber key={'total'-key} maxLength="10" name="total" className="cost-input cost-total" value={getTotalFormattedNumeric(cost)} positive={true}  placeholder="200.00" negative={true} thousand={true}
                           onChange={(e) => onChangeInput("total", e)} separator={'.'} integer={10} fraction={2}/>
          </div>
          <div className="info-icon container-drop-zone">
            {receipts.length > 0 || receiptsImages.length > 0 ?
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_receipt_24px.svg"}
                          className={"icon-arrow-content"} onClick={() => handleOpenRecipe(cost.id)}
                          beforeInjection={svg => svg.classList.add("icon-arrow-content")}/>
                : showAlert ?
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_receipt_24px.svg"}
                          className={"icon-gray"} onClick={() => openRecipes(index, cost.id)}
                          beforeInjection={svg => svg.classList.add("icon-gray")}/>
                :(cost.id && <Dropzone id="dropZone" onDrop={(file) => handleOpenRecipe(cost.id, file)}
                          accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}>
                  {({getRootProps, getInputProps}) => (
                      <div {...getRootProps()} className="dropzone-container">
                        <input {...getInputProps()} />
                        <img className="icon-dropzone"/>
                      </div>
                  )}
                </Dropzone>)
            }

            {
              showActionRow &&
              <Fragment>
                {
                  isCarOrTask === 'C' &&
                  <div className="info-icon">
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_solid_task.svg'}
                              className="icon-task-content" onClick={openTaskRepair}
                              beforeInjection={svg => svg.classList.add(cost.taskId ? 'icon-has-task' : 'icon-task-content')}/>
                  </div>
                }
                <div className="info-icon">
                  <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_delete.svg'}
                            className="icon-action-content-delete" onClick={removeTaskRepair}
                            beforeInjection={svg => svg.classList.add('icon-action-content-delete')}/>
                </div>
              </Fragment>
            }

          </div>

          {(showRecipe && index === rowActive) && (
              <DPModalPreview deleteReceipt={(id,countReceipts)=> handleDeleteReceipt(id,countReceipts)}
                              showRecipe={showRecipe}
                              onDropPhoto={onDropPhoto}
                              receipts={receipts}
                              receiptsImagesPrev={receiptsImages}
                              closeModal={(newReceipts) => {
                                if (isCarOrTask === 'T') {
                                  onClosePreview(newReceipts, cost.id, index);
                                } else {
                                  onClosePreview(newReceipts, cost.id, index );
                                }
                                updateShowRecipe(false);
                              }}
                              onHide={() => {updateShowRecipe(false)
                                              setReceipts([])
                              }}

              />
          )}
    </StyledRow>
  )
}

RowCost.propTypes = {
  index: PropTypes.number,
  key: PropTypes.string,
  onChange: PropTypes.func,
  removeTask: PropTypes.func,
  cost: PropTypes.object,
  onDropPhoto : PropTypes.func,
  receipts: PropTypes.array,
  deleteReceipt: PropTypes.func,
  onClosePreview: PropTypes.func,
  isCarOrTask: PropTypes.string,
  rowActive: PropTypes.number,
  updateRowActive: PropTypes.func
};

RowCost.defaultProps = {
  receipts: []
}

export default RowCost